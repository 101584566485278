import React                from "react"
import RawHtml              from 'entrada-ui/RawHtml'
import Img                    from "gatsby-image"
import {
    Col,
    Row,
    Container
}                             from 'react-bootstrap'
import cx                     from 'utils/classnames'
import Link                   from 'components/Link'
import Seo                    from 'components/Seo'
import styles                 from './styles.module.scss'

const GeneralContentTemplate = ({ pageContext: { data } }) => {
  const {
    siteMetadata,
    content,
    slug,
    title,
    wordpress_id,
    acf: {
        page_heading,
        image
    },
    yoast_meta
  } = data

  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <Container>
        <h1>{page_heading}</h1>
        {image &&
        <Img fluid={image.localFile.childImageSharp.fluid} className={styles.heroImage} />
        }
        <br/>

        <RawHtml html={content} />

      </Container>
    </>
  )
}
export default GeneralContentTemplate

